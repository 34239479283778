module controllers {
    export module customs {
        interface IAccountScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        export class accountCreateCtrl {

            static $inject = [
                "$scope",
                "$rootScope",
                "generalService",
                "$q",
                "$anchorScroll",
                "$uibModal",
                "bsLoadingOverlayService",
                "entityService",
                "$state",
                "$timeout",
                "$transitions",
                "classificationValueService",
                "documentRepositoryService",
                "lookupService",
                "accountService",
                "currencyService",
                "countryService",
                "customsPartyService",
                "customsOfficeCodeService"
            ];

            accountId: number = 0;
            account: interfaces.customs.IAccount;

            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;
            ownerEntityId: number;

            //Declaration Country Variables
            declarationCountryId: number = 0;
            declarationCountryList: Array<interfaces.applicationcore.IDropdownModel>;
            declarationCountry: interfaces.applicationcore.IDropdownModel;
            //Customs Office Variables
            customsOfficeId: number = 0;
            customsOfficeList: Array<interfaces.applicationcore.IDropdownModel>;
            customsOffice: interfaces.applicationcore.IDropdownModel;

            accountNumber: string;

            //Currency Variables
            currencyId: number = 0;
            currencyList: Array<interfaces.applicationcore.IDropdownModel>;
            currency: interfaces.applicationcore.IDropdownModel;

            //Customs Party Variables
            customsPartyId: number = 0;
            customsPartyList: Array<interfaces.applicationcore.IDropdownModel>;
            customsParty: interfaces.applicationcore.IDropdownModel;

            IsLoading: boolean = false;
            IsValid: boolean = false;
            constructor(
                private $scope: IAccountScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private accountService: interfaces.customs.IAccountService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private countryService: interfaces.applicationcore.ICountryService,
                private customsPartyService: interfaces.master.ICustomsPartyService,
                private customsOfficeCodeService: interfaces.tariff.ICustomsOfficeCodeService
            ) {    

                var loadPromises: ng.IPromise<any>[] = [];

                //loadPromises.push(this.loadCurrentEntity());
                loadPromises.push(this.loadEntities());
                loadPromises.push(this.loadDeclarationCountries());//DeclarationCountry
                loadPromises.push(this.loadCurrencies());//Currency
                loadPromises.push(this.loadCustomParties());//Customs Party

                bsLoadingOverlayService.wrap({
                    referenceId: 'create.account'
                }, () => {
                    return $q.all(loadPromises);
                });

            }

            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadCurrentEntity() {
                if (this.$rootScope.Entity.Id) {
                    this.ownerEntityId = this.$rootScope.Entity.Id;
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.ownerEntityId = resultEntity.Id;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
            }
            
            loadCustomParties() {
                return this.customsPartyService.getList().query((successList) => {
                    this.customsPartyList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }
            //loadCustomParties() {
            //    return this.customsPartyService.getList().query({
            //    }, (resultList) => {
            //    }).$promise;
            //}

            declarationCountryChange() {
                this.customsOffice = undefined;
                this.customsOfficeList = [];
                this.customsOfficeId = 0;
            }
            loadCustomsOffices(searchText: string) {
                if (this.account.DeclarationCountry.Id > 0) {
                    return this.customsOfficeCodeService.getForDropdown(searchText, this.account.DeclarationCountry.Id).query().$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }
            
            loadDeclarationCountries() {
                return this.countryService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }
            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }
            loadCreditTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CreditType
                }, (resultList) => {
                }).$promise;
            }
            close() {
                this.$state.go("^");
            }

            SaveAccount(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.accountService.save().save(this.account, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    this.accountId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.account'
                },
                    () => {
                        return this.SaveAccount().then((data: boolean) => {
                            if (data) {
                                if (this.accountId != 0) {
                                    this.$anchorScroll("topAnchor");
                                    this.$state.go("auth.MasterData.Account.Update", { accId: this.accountId });
                                }                              
                            }
                        });
                    });

            }

        };

        angular.module("app").controller("accountCreateCtrl", controllers.customs.accountCreateCtrl);
    }
}
